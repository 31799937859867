html, body {
  background-color: black!important;
  font-family: "Gochi Hand"!important;
  font-size: 24px!important;
}

a {
  font-size: 20px!important;
  color: white!important;
  font-family: "Gochi Hand"!important;

}
p {
  font-size: 18px!important;
  font-family: "Gochi Hand"!important;
  color: white;
  text-align: left;
}

h1, h2, h3, h4, h5  { 
  font-family: "Permanent Marker"!important; font-weight: normal!important; color: black; 
}

/* Top text for all sections */
h1 { font-size: 24px!important; }

/* Hero Just Under Very Top text */
h3 { font-size: 18px!important; }

/* More minor title text */
h4 { font-size: 16px!important; }

/* Very minor title text */
h5 { font-size: 14px!important; }

@media screen and (min-width: 576px) {
  h1 { font-size: 28px!important; }
  h3 { font-size: 21px!important; }
  h4 { font-size: 18px!important; }
  h5 { font-size: 16px!important; }
  p  { font-size: 19px!important; }
}

@media screen and (min-width: 768px) {
  h1 { font-size: 32px!important; }
  h3 { font-size: 24px!important; }
}

@media screen and (min-width: 992px) {
  h1 { font-size: 36px!important; } 
  h3 { font-size: 27px!important; }
  p  { font-size: 22px!important; }
}

@media screen and (min-width: 1200px) {
  h1 { font-size: 40px!important; }
  h3 { font-size: 30px!important; }
  p  { font-size: 23px!important; }
}


.red { color: #D0021B!important; }
.orange { color: #F5A623!important; }
.yellow { color: #F8E71C!important; }
.green { color: #7ED321!important; }
.blue { color: #4A90E2!important; }
.purple { color: #9013FE!important; }
.pink { color: #BD10E0!important; }